import { merge } from 'lodash'
import contentSettings from '~/sites/cvb/content.settings'

const overloadSettings = {
  functions: {
    minimalPricePeriod: false,
  },
}

export default merge(contentSettings, overloadSettings)
